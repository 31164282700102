import React from 'react';
//Importación de monday
import mondaySdk from "monday-sdk-js";
const monday = mondaySdk();

const ConnectionToMondayComponent =  () => {

    const testQuery = () => {
        let boardId = '4124672387';
        let mondayQuery = `query {boards (ids: ${boardId}) {owner{ id }  columns { archived id pos settings_str title type width }}}`; 
        console.log('queryMonday', mondayQuery);
        monday.api(mondayQuery).then(res => {
            console.log('Detalles del tablero');
            console.log(res);
            /* { data: { users: [{id: 12312, name: "Bart Simpson"}, {id: 423423, name: "Homer Simpson"}] } } */
          }, error => {
                console.log('Ocurrio un error');
          });          
    };

    const test2Query = () => {
        let mondayQuery = `query { users { id, name } }`; 
        console.log('queryMonday', mondayQuery);
        monday.api(mondayQuery).then(res => {
            console.log('Usuarios');
            console.log(res);
            /* { data: { users: [{id: 12312, name: "Bart Simpson"}, {id: 423423, name: "Homer Simpson"}] } } */
          }, error => {
                console.log('Ocurrio un error');
          });          
    };

    testQuery();

    test2Query();

    return(
        <div className='connectionConatiner'>
            <p>Me conectare con monday</p>
        </div>
    );
};  

export default ConnectionToMondayComponent;